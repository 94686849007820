<template>
  <b-modal id="modal-detail-media" size="lg" hide-footer>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="InfoIcon" class="mr-05" />
        <span class="fs-18">
          Detail Media Siputra pada Scraper "<strong> {{ scraper }} </strong>"
        </span>
      </div>
    </template>
    <div class="scroller" style="max-height: 40rem;">
      <loader v-if="is_chart_loading" />
      <div v-else class="border rounded px-1 mb-2">
        <linechart-component
          v-if="chart_data.categories.length > 0"
          :data="chart_data"
          class="my-1"
        />
        <div v-else class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Grafik tidak bisa menampilkan data yang kosong
            </p>
          </center>
        </div>
      </div>
      <loader v-if="is_loading" />
      <div v-else>
        <div v-for="item in data" :key="item._id">
          <b-card no-body class="border border-info">
            <div class="p-1">
              <b-card-title
                class="mb-1 text-justify"
                style="font-size: 14px !important"
              >
                {{ item.title }}
              </b-card-title>
              <div class="d-flex align-items-center">
                <i class="bi bi-globe ml-1" style="margin-right: 5px"></i>
                <span>{{ item.account }}</span>

                <i class="bi bi-calendar ml-2" style="margin-right: 5px"></i>
                Dipublish:
                <span class="ml-1">{{ parseDate(item.date) }}</span>
                <i class="bi bi-clock ml-2" style="margin-right: 5px"></i>
                Dibuat:
                <span class="ml-1">{{
                  parseDate(dateConvertUTC7(item.created_at))
                }}</span>
              </div>
            </div>
            <div class="row p-1">
              <div class="col-md-6">
                <a :href="item.file_source" target="_blank">
                  <b-button block size="sm" variant="info">
                    <i class="bi bi-box-arrow-up-right"></i> Kunjungi
                  </b-button>
                </a>
              </div>
              <div class="col-md-6">
                <b-button
                  block
                  size="sm"
                  variant="secondary"
                  @click="copyURL(item.file_source, item._id)"
                >
                  <span v-if="iscopied && id_copied == item._id">
                    <i class="bi bi-clipboard-check"></i>
                    URL Berhasil Disalin
                  </span>
                  <span v-else>
                    <i class="bi bi-clipboard"></i> Salin URL
                  </span>
                </b-button>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import loader from "../loader.vue";
import helper from "@/helper/helper";
import linechart_component from "@/views/components/lineChart.vue";

export default {
  name: "detailMediaModal",
  components: {
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    loader,
    "linechart-component": linechart_component,
  },
  props: {
    id: String,
  },
  data() {
    return {
      scraper: "",
      is_chart_loading: true,
      is_loading: true,
      page: this.$route.query.page ? this.$route.query.page : 1,
      items: this.$route.query.items ? this.$route.query.items : 10,
      data: null,
      iscopied: false,
      id_copied: null,
      chart_data: {
        height: "200",
        colors: [],
        categories: [],
        series: [],
      },
    };
  },
  methods: {
    getGrafikData(id) {
      this.scraper = id;
      this.is_chart_loading = true;
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
        page: this.page,
        items: this.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api =
        process.env.VUE_APP_API_URL +
        "media/media-online/stats/" +
        id +
        "?" +
        query;
      useJwt.axiosIns
        .get(api)
        .then((res) => {
          if (res.status === 200) {
            this.chart_data.categories = res.data.categories;
            for (let i = 0; i < res.data.series.length; i++) {
              let color = "#28c76f";
              this.chart_data.colors.push(color);
              let data = {
                name: res.data.series[i].name,
                data: res.data.series[i].value,
              };
              this.chart_data.series[i] = data;
            }
          } else {
            this.chart_data = {
              colors: [],
              categories: [],
              series: [],
            };
            this.is_chart_loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_chart_loading = false;
        });
    },
    getData(id) {
      this.scraper = id;
      this.is_loading = true;
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
        page: this.page,
        items: this.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api =
        process.env.VUE_APP_API_URL + "media/media-online/" + id + "?" + query;
      useJwt.axiosIns
        .get(api)
        .then((res) => {
          this.data = res.data.media_online;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    parseDate(date) {
      return helper.dateParser(date);
    },
    dateConvertUTC7(date) {
      return helper.dateConvertUTC7(date);
    },
    copyURL(url, id) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.iscopied = true;
          this.id_copied = id;
          setTimeout(() => {
            this.iscopied = false;
          }, 2000);
        })
        .catch((error) => {
          console.error("Gagal menyalin teks ke clipboard: ", error);
        });
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
