<template>
  <div id="media-siputra-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <div>
          <!-- title -->
          <b-card-title class="d-flex align-items-center">
            <i class="bi bi-clipboard-plus"></i>
            <span style="margin-left: 5px"> SIPUTRA </span>
          </b-card-title>
        </div>
        <!-- filter component -->
        <div class="d-flex">
          <!-- date filter -->
          <date-picker-component />
          <!-- keyword filter -->
          <b-input-group class="input-group-merge ml-1" style="width: 15rem">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan kata kunci..."
              v-model="name"
              style="height: 3rem"
            />
          </b-input-group>
        </div>
      </b-card-header>
    </b-card>
    <b-card class="h-100">
      <!-- title -->
      <b-card-title class="d-flex align-items-center">
        <span style="margin-left: 5px">
          Grafik Tren Jumlah Input Berita Media Siputra
        </span>
      </b-card-title>
      <!-- loader component -->
      <loader-component v-if="is_graph_loading" />
      <b-card-body v-else>
        <!-- linechart component -->
        <linechart-component
          v-if="chart_data.categories.length > 0"
          :data="chart_data"
        />
        <div v-else class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Grafik tidak bisa menampilkan data yang kosong
            </p>
          </center>
        </div>
      </b-card-body>
    </b-card>
    <b-card no-body class="h-100">
      <!-- title -->
      <b-card-title class="d-flex align-items-center p-2">
        <span style="margin-left: 5px">
          Daftar Jumlah Input Berita Media Siputra
        </span>
      </b-card-title>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table-component -->
      <table-component
        v-else
        :data="table_data"
        :is_row_clicked="true"
        :viewDetail="viewDetail"
      />
    </b-card>
    <!-- detail media modal component -->
    <detail-media-component ref="detail" />
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import linechart_component from "@/views/components/lineChart.vue";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import datePicker from "@/views/components/datePicker.vue";
import detailMediaModalVue from "@/views/components/siputra/detailMediaModal.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";

export default {
  name: "MediaSiputra",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    "linechart-component": linechart_component,
    "table-component": dataTable,
    "loader-component": loader,
    "date-picker-component": datePicker,
    "detail-media-component": detailMediaModalVue,
  },
  data() {
    return {
      is_loading: true,
      is_graph_loading: true,
      from_date: this.$route.query.from_date,
      to_date: this.$route.query.to_date,
      tier: null,
      page: this.$route.query.page ? this.$route.query.page : 1,
      items: this.$route.query.items ? this.$route.query.items : 10,
      name: "",
      chart_data: {
        colors: [],
        categories: [],
        series: [],
      },
      table_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "_id",
            label: "nama media",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
            thStyle: { width: "auto" },
          },
          {
            key: "count",
            label: "jumlah berita",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
        ],
        items: null,
      },
      CancelToken: null,
      cancelTokenGraph: null,
    };
  },
  created() {
    this.getTrendGraph();
    this.getData();
  },
  watch: {
    "$route.query"() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.items = this.$route.query.items ? this.$route.query.items : 10;
      this.from_date = this.$route.query.from_date;
      this.to_date = this.$route.query.to_date;
      this.getData();
    },
    "$route.query.from_date"() {
      this.from_date = this.$route.query.from_date;
      this.to_date = this.$route.query.to_date;
      this.getTrendGraph();
    },
    "$route.query.to_date"() {
      this.from_date = this.$route.query.from_date;
      this.to_date = this.$route.query.to_date;
      this.getTrendGraph();
    },
    name() {
      if (this.name.length > 2 || this.name.length == 0) {
        this.updateQuery("page", 1);
        this.getTrendGraph();
        this.getData();
      }
    },
  },
  methods: {
    getRandomHexColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getData() {
      this.is_loading = true;
      if (this.CancelToken) {
        this.CancelToken.cancel();
      }
      this.CancelToken = axios.CancelToken.source();
      this.is_loading = true;
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
        page: this.page,
        items: this.items,
        ...(this.name ? { name: this.name } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "media/media-online?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.CancelToken.token })
        .then((res) => {
          if (res.status == 200 && res.data.media_online.length > 0) {
            this.table_data.items = res.data.media_online;
            this.table_data.page_info = res.data.page_info[0];
          } else {
            this.table_data.items = [];
            this.table_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          if (this.table_data.items) {
            this.is_loading = false;
          }
        });
    },
    getTrendGraph() {
      this.is_graph_loading = true;
      if (this.cancelTokenGraph) {
        this.cancelTokenGraph.cancel();
      }
      this.cancelTokenGraph = axios.CancelToken.source();
      this.chart_data = {
        colors: [],
        categories: [],
        series: [],
      };
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
        ...(this.name ? { name: this.name } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api =
        process.env.VUE_APP_API_URL + "media/media-online/stats?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelTokenGraph.token })
        .then((res) => {
          if (res.status === 200) {
            this.chart_data.categories = res.data.categories;
            for (let i = 0; i < res.data.series.length; i++) {
              let color = "#28c76f";
              this.chart_data.colors.push(color);
              let data = {
                name: res.data.series[i].name,
                data: res.data.series[i].value,
              };
              this.chart_data.series[i] = data;
            }
          } else {
            this.chart_data = {
              colors: [],
              categories: [],
              series: [],
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          if (this.chart_data.categories.length > 0) {
            this.is_graph_loading = false;
          }
        });
    },
    viewDetail(id) {
      this.$bvModal.show("modal-detail-media");
      this.$refs.detail.getData(id);
      this.$refs.detail.getGrafikData(id);
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
  },
};
</script>